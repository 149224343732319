export default function Quantity({qty, changeQty}) {
  // Set qty to zero for the 0 button; add or subtract the number otherwise
  const MAX_QTY = 25
  function setNumber(e) {
    const value = parseInt(e.currentTarget.value)
    const q = (value ? Math.min(Math.max(qty + value, 0), MAX_QTY) : 0)
    changeQty(q)
  }

  // Create one Button component
  const Btn = (variant, value) => <button className={'qty-button ' + variant} disabled={variant === 'qty'} onClick={setNumber} value={value}>{value}</button>

  return (
    <div className='qty-box'>
      { Btn('neg', '-1') }
      { Btn('qty',  qty) }
      { Btn('pos', '+1') }
    </div>
  )
}

// Removed 0 & +2 buttons
// return (
//   <div className='qty-box'>
//     { Btn('zero', '0') }
//     { Btn('neg', '-1') }
//     { Btn('qty',  qty) }
//     { Btn('pos', '+1') }
//     { Btn('pos', '+2') }
//   </div>
// )
