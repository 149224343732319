import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
//
import Quantity from '../components/Quantity'
import Sweetness from '../components/Sweetness'
import Temperature from '../components/Temperature'
import { getSrcPath, removeBrokenIcon  } from '../Utils'

export default function ItemPage({lang}) {
  const [itemSel, setItemSel] = useState({})
  const [level, setLevel] = useState('100')
  const [temp, setTemp] = useState('Iced')
  const [qty, setQty] = useState(1)
  const [isDisabled, setIsDisabled] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (! location.state)
      return
    //setCart(gCart)
    console.log('state = ', JSON.stringify(location.state))
    setItemSel(location.state)
    return () => {
        location.state = null
    }
  }, [location])

  useEffect(() => {
    setIsDisabled(qty <= 0)
  }, [qty])

  // Add the current item to cart
  // Create the object quan in order to call the child's function
  function addToCart() {
    if (! itemSel  ||  itemSel.qty === 0) {
      console.log('invalid itemSel')
      return
    }
    if (itemSel.index === undefined) { // add
      itemSel.qty = qty;
      itemSel.level = level
      itemSel.temp = temp
      console.log('add item ', JSON.stringify(itemSel))
      navigate('/cart', { state: itemSel })
    }
    else { // edit
 //     Object.assign(cart[itemSel.index], itemSel); // update the item
    }
    setQty(0);
  }

  function changeQty(q) {  
    setQty(q);
    setItemSel(itemSel);
  }

  function goBack() {
    navigate(-1)
  }

  const Header = (() => {
    return <div className='item-header' style={{backgroundColor: itemSel.bk}}>
      {itemSel.name + ' ' + itemSel.price}
      <span className='close-button' onClick={goBack}>X</span>
    </div>
  })

  // Originally it returns <>...</> but it gets a "Failed to execute removeChild on Node" error
  // Changing to <div>...</div> solved the problem
  return (
    <div style={{width: '100%'}}> 
      <Header />
      <img src={getSrcPath('ilt')} alt={itemSel.name} className='pop' onError={removeBrokenIcon} />
      <Sweetness level={level} setLevel={setLevel} lang={lang} />
      <div style={{height: '10px'}} />
      <Temperature temp={temp} setTemp={setTemp} lang={lang} />
      <div className='qty-cart-container'>
        <Quantity key={qty} qty={qty} changeQty={changeQty} />
        <div className='cart-button-box'>
          <button className='emoji-button cart' disabled={isDisabled} onClick={addToCart}>➜🛒</button>
        </div> 
      </div>
    </div>
  )
}

//<img src={itemSel.src} alt={itemSel.name} className='pop' onError={removeBrokenIcon} />