import { useEffect } from 'react'
//
import { addOrRemoveClass, formatTemp } from '../Utils'
// css
import '../css/App.css'

export default function Temperature({temp, setTemp, lang}) {
  const temps = [ 'Iced', 'Less', 'NoIce', 'Warm', 'Hot' ]
  
  useEffect(() => {
    document.querySelectorAll('.group-button.temp').forEach(btn => {
      addOrRemoveClass((btn.id === temp), btn, 'selected')
    })
  }, [temp])

  return (
    <div>
      { temps.map((t) => (
        <button type='button' key={t} id={t} onClick={(e) => setTemp(e.currentTarget.id)} 
         className={'group-button temp ' + t + (t === temp ? ' selected' : '')} >
          {formatTemp(t, lang)}
        </button>
      ))}
    </div>
  )
}

// function clicked(e) { setTemp(e.currentTarget.id) }

// return (
//   <div>
//     { temps.map((t) => (
//       <button type='button' key={t} id={t} onClick={clicked} 
//        className={'group-button temp ' + t + (t === temp ? ' selected' : '')} >
//         {formatTemp(t, lang)}
//       </button>
//     ))}
//   </div>
// )