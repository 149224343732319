import { useCallback, useEffect, useState } from 'react'
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
//
import LangSel from './components/LangSel'
import { checkLang } from './language/Langs'
import { getSrcPath, useLocalStorageValue  } from './Utils';
// Pages
import Home from './pages/Home'
import Menu from './pages/Menu'
import Item from './pages/Item'
import Cart from './pages/Cart'
//import NotFound from './pages/NotFound'
// css
import './css/App.css'

export default function App() {
  // Get the language setting from the params of URL in the form of "path?lang=en"
  let langParam = new URLSearchParams(useLocation().search).get('lang')
  //console.log('langParam1 =', langParam)
  langParam = checkLang(langParam)
  //console.log('langParam2 =', langParam)
  // If it has been run before, use the previously saved language setting
  const [lang, setLang] = useLocalStorageValue(langParam, 'LANG')
  const changeLang = useCallback((newLang) => {
      setLang(newLang)
  }, [setLang])

  // Make sure it could only start at the Home or Menu page
  const [isFirstRun, setIsFirstRun] = useState(true)
  const navigate = useNavigate()
  const path = useLocation().pathname
  useEffect(() => {
    if (isFirstRun) {
      //console.log('navigate to menu!');
      setIsFirstRun(false)
      if (path !== '/home'  &&  path !== '/menu') {
        navigate('/menu')
      }
    }
  }, [isFirstRun, path, navigate])

  const Header = () => (
    <div className='app-header'>
      <img src={getSrcPath('teaisland')} alt='' />
    </div> 
  )

  const PageLink = (({id, char}) => (
      <NavLink to={id} 
        className={({ isActive }) => 'menu-button' + (isActive ? ' active' : '') }
      > 
        <span>{char}</span>
      </NavLink>
  ))
  
  const Pages = ({lang}) => (
    <div className='app-body'>
      <Routes>
        <Route exact path='/' element={<Home lang={lang}/>} />
        <Route path='/home' element={<Home lang={lang}/>} />
        <Route path='/menu' element={<Menu lang={lang}/>} />
        <Route path='/item' element={<Item lang={lang}/>} />
        <Route path='/cart' element={<Cart lang={lang}/>} />
        <Route path='*' element={<Menu lang={lang}/>} />
      </Routes>
    </div>
  )

  // The header is shown only on the Home page
  return (
    <div className='app-main'>
      { (path === '/home') && <Header /> }
      <div className='menu-bar' >
        <PageLink id='home' char='🏠'/>
        <PageLink id='menu' char='🥤'/>
        <PageLink id='cart' char='🛒'/>
        <LangSel changeLang = {changeLang} lang={lang} />
      </div>
      <Pages lang={lang}/>
    </div>
  )
}
