export const tokens = {
    location:  [ 'We are located inside the&nbsp;Pleasenton 99&nbsp;Ranch&nbsp;supermarket',
                 '本店位於 Pleasenton 大華超市內' ],
                 
    //shopname:   [ 'Tea Island', '鮮茶坊' ],
    //sweetness:  [ 'Sweetness', '甜度' ],
    //sweet100:   [ 'Sweet', '正常' ],
    sweet100:   [ 'Sweet', '甜' ],
    sweet75:    [ 'Less', '少糖' ],
    sweet50:    [ 'Half', '半糖' ],
    sweet25:    [ 'Low', '微糖' ],
    sweet0:     [ 'No', '無糖' ],
 
    //temp:        [ 'Temp.', '溫度' ],
    tempIced:   [ 'Iced', '冰' ],
    tempLess:   [ 'Less', '少冰' ],
    tempNoIce:  [ 'No Ice', '無冰' ],
    tempWarm:   [ 'Warm', '溫' ],
    tempHot:    [ 'Hot', '熱' ],

    subtotal:   [ 'Subtotal', '合計'],
    tax:        [ 'Tax', '稅' ],
    total:      [ 'Total', '總計' ],

    //home:       [ 'Home', '主頁' ],
    //menu:       [ 'Menu', '餐牌', '菜單' ],
    //cart:       [ 'Cart', '訂單' ], // '營業時間'
};

export const home = [ 'Brief Introduction', '簡單的介紹' ];
