import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Overlay from 'react-bootstrap/Overlay';
//
import { langs } from '../language/Langs';
import { getSrcPath } from '../Utils';
// css
import 'bootstrap/dist/css/bootstrap.css'

// The Language Selector component
function LangSel({lang, changeLang}) {
  const [show, setShow] = useState(false);
  
  // Hide the buttons when they are shown and the click is somewhere else
  function hideButtons(e) { (e.target.id !== 'langSel') && setShow(false) }
  
  useEffect(() => {
    if (! show) return;
    window.addEventListener('click', hideButtons)
    return () => window.removeEventListener('click', hideButtons)
  }, [show])

  // Change the language when one of the button is clicked
  const clicked = (id) => {
    setShow(false);
    changeLang(id); 
  }
  const langButton = (id, text) => <Button key={id} variant={id === lang ? 'warning' : 'success'} onClick={() => clicked(id)}>{text}</Button>;

  const target = useRef(null)

  return (<>  
    <span style={{backgroundColor: 'green', float: 'right', border: 'none'}} ref={target} onClick={() => setShow(!show)}>
      
      <img id='langSel' src={getSrcPath('langsel')} alt='' className='menu-langsel' />
    </span>
    <Overlay target={target.current} show={show} placement='left'>
    { // Need to rename these properties to get rid of the "React does not recognize xxx" warnings
    ({
        show: _show,
        arrowProps: _arrowProps,       
        hasDoneInitialMeasure: _hasDoneInitialMeasure,
        ...props
    }) => (
        <ButtonGroup size='lg' {...props}>
          {langs.map((l) => langButton(l.id, l.text))}
        </ButtonGroup>
    )}
    </Overlay>
  </>); 
}

export default React.memo(LangSel);
