import { formatToken, getSrcPath } from '../Utils'

export default function Home({lang}) {
  const URL = 'http://maps.google.com/?q=4299 Rosewood Dr., Pleasanton, CA 94588'

  function openMaps() { 
    window.open(URL, '_blank')
    // const newTab = document.createElement('a')
    // newTab.href = URL
    // newTab.target = '_blank'
    // newTab.click()
  }

//<button onClick={openMaps}>Maps</button>
//<a href={URL}>Google Maps</a>
//style={{fontWeight: 'normal'}}
//<div style={{fontWeight: 'normal'}}>{formatToken('location', lang)}</div>

  return (
    <div>
      <img src={getSrcPath('ranch99')} alt='' className='home-image' />
      <div className='home'>
        <p>{formatToken('location', lang)}</p>
        <div>4299 Rosewood Dr.</div>
        <div>Pleasanton, CA 94588</div>
        <div>(510) 623-1523</div>
      </div>
      <img src={getSrcPath('map')} alt='' className='home-map' onClick={openMaps}/>
    </div>
  );
}
