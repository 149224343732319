import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'
//
import { formatPercentage, formatPrice, formatTemp, formatText, formatToken } from '../Utils'
// css
import '../css/App.css'

let gCart = [] 

export default function Cart({lang}) {
  const [cart, setCart] = useState([])

  const location = useLocation()
  const navigate = useNavigate()
  const URL = process.env.REACT_APP_SERVER
  const socket = io(URL)
 
  useEffect(() => {
    if (! location.state)
      return
    console.log('cart state = ', JSON.stringify(location.state))
    updateCart(gCart.concat([location.state]))
    return () => {
        location.state = null
    }
  }, [location])

  useEffect(() => {
    const handleBeforeUnload = () => {
      // if (socket.connected) {
      //   socket.disconnect()
      // }
      // console.log('Running unload', socket.id, 'connected', socket.connected)
    };  
    // handles when page is unloaded
    window.addEventListener('beforeunload', handleBeforeUnload)
    // cleanup function handles when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      handleBeforeUnload();
    };
  }, [socket]);

  useEffect(() => {
    console.log('Cart', JSON.stringify(cart))
  }, [cart])

  function updateCart(c) {
    gCart = c
    setCart(gCart)
  }

  //const editItem = (e) => {
    // Go to the clicked item
    // const index = e.currentTarget.getAttribute('index');
    // const item = cart[index]
    // item.index = index;
    // setShowCart(false);
    // changeQty(item.qty);
  //}

  //const sound = new Audio('/assets/ding.mp3')

  function makeOrder() {
    const order = {
      phone: '510-555-1234',
    }
    order.items = cart.map((item) => {
      return {
        name: item.n[1],
        qty: item.qty,
        level: item.level,
      }
    })
    console.log('Make Order: ' + JSON.stringify(order))
    console.log(socket.id, 'connected = ', socket.connected);
    socket.timeout(1000).emit('store', order, () => {
      //sound.play()
      console.log('emitted to store')
    })
    // socket.disconnect()
    // console.log('Connected2 = ', socket.connected)
    updateCart([])
    gotoMenu()
  }

  function gotoMenu() {
    navigate('/menu')
  }

  const GridRow = (name, extra, sign, price, bold) => (
    <div className={'grid-container' + (bold ? ' bold' : '')}>
      <div>{formatToken(name, lang) + extra}</div>
      <div>{sign}</div>
      <div>{formatPrice(price)}</div>
    </div>
  )

  cart.forEach((item) => item.amount = item.p * item.qty)
  const subtotal = cart.reduce(((total, item) => total + item.amount), 0)
  const taxRate = parseFloat(process.env.REACT_APP_TAX_RATE)
  const tax = subtotal * taxRate
  const total = subtotal + tax

  return(<>     
    {cart.map((item, i) => (
      <div key={'g'+ i} className='grid-container full'>
        <div key={'n'}>{formatText(item.n, lang)}</div>
        <div key={'s'} style={{color: 'brown'}}>{item.level + '%'}</div>
        <div key={'t'}>{formatTemp(item.temp, lang)}</div>
        <div key={'p'}>{item.price}</div>
        <div key={'x'}>x</div>
        <div key={'q'}>{item.qty}</div>
        <div key={'='}>=</div>
        <div key={'a'}>{formatPrice(item.amount)}</div>
      </div>
    ))}
    { GridRow('subtotal', '', '=', subtotal) }
    { GridRow('tax', ' (' + formatPercentage(taxRate) + ')', '+', tax) }
    { GridRow('total', '', '=', total, true) }
    <div className='button-container' >
      <button className='emoji-button menu' onClick={gotoMenu} style={{backgroundColor: '#00a3cc'}}>🥤⬅</button>
      <button className='emoji-button order' onClick={makeOrder}>⫸</button>
    </div>
  </>)
}

//{ GridRow('tax', ' (' + formatPercentage(data.tax) + ')', '+', tax) }
