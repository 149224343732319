import { useEffect } from 'react'
//
import { addOrRemoveClass, formatToken } from '../Utils'
// css
import '../css/App.css'

const include = ((id, level) => parseInt(id) <= parseInt(level))

export default function Sweetness({level, setLevel, lang}) {
  const levels = [ '100', '75', '50', '25', '0' ]
  
  useEffect(() => {
    document.querySelectorAll('.group-button.sweet').forEach(btn => {
      addOrRemoveClass((btn.id === level), btn, 'selected')
    })
    document.querySelectorAll('.percent-button').forEach(btn => {
      if (btn.id !== '0') {
        addOrRemoveClass(include(btn.id, level), btn, 'included')
      }
      addOrRemoveClass((btn.id === level), btn, 'selected')
    })
  }, [level])

  function clicked(e) { setLevel(e.currentTarget.id) }

  const SweetButtons = () => (
    <div>
      { levels.map((l) => (
        <button type='button' key={l} id={l} onClick={clicked} 
         className={'group-button sweet' + (l === level ? ' selected' : '')} >
          {formatToken('sweet' + l, lang)}
        </button>
      ))}
    </div>
  )

  const PercentButtons = () => (
    <div>
      { levels.map((l) => (
        <button type='button' key={l} id={l} onClick={clicked} 
          className={'percent-button'
          + (l === '100' ? ' left' : '')
          + (l === '25' ? ' right' : '')
          + (l === '0' ? ' zero' : '')
          + (l !== '0' && include(l, level) ? ' included' : '')
          + (l === level ? ' selected' : '')
          }>
            {l + '%'}
        </button>
      ))}
    </div>
  )

  return (
    <div> 
      <SweetButtons />   
      <PercentButtons />
    </div>
  )
}
