import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
//
import { addOrRemoveClass, formatPrice, formatText, getSrcPath } from '../Utils'
import { drinks } from '../language/ItemTokens'
// css
import '../css/App.css'

export default function Menu({lang}) {
  const [cat, setCat] = useState('0')
  const navigate = useNavigate()

  useEffect(() => {
    document.querySelectorAll('.cat-button').forEach(btn => {
      addOrRemoveClass((btn.id === cat), btn, 'selected')
    })
  }, [cat])
 
  function changeCat(e) {
    setCat(e.currentTarget.id)
  }

  function selectItem(e) {
    //console.log('list:', JSON.stringify(list));
    console.log('id:', e.currentTarget.id)
    const category = drinks[cat]
    const item = category.list.findItem(e.currentTarget.id)
    item.index = undefined
    item.src = getSrcPath(item.id)
    item.name = formatText(item.n, lang) // formatted string
    item.price = formatPrice(item.p) // formatted string
    item.bk = category.bk
    navigate('/item', { state: item })
  }

  return (<div className='menu-page'>
    <div className='menu-row category'>
      {drinks.map((item, i) => (
        <div key={item.id}>
          <div id={i} className='cat-button' style={{backgroundColor: item.bk}} onClick={changeCat}>
            {formatText(item.n, lang)}
          </div>
        </div>
      ))}
    </div>
    <div className='menu-row drinks'>
      { drinks[cat].list.data.map((item) => ( 
        <div className='menu-page' key={item.id} id={item.id} onClick={selectItem}>
          <div className='menu-row name'>{formatText(item.n, lang)}</div>
          <div className='menu-row price'>{formatPrice(item.p)}</div>
      </div>
      ))}
    </div>
  </div>)
}
